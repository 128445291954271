import React from "react"
import { Text } from "theme-ui"

import Part from "../../../components/adventures/short-stories/Part"
import Section from "../../../components/adventures/Section"
import Block from "../../../components/adventures/Block"

import elderWardenImage from '../../../images/spots/elder-warden.png'
import lichenImage from '../../../images/spots/lichen.png'

export default function Part04() {
  return (
    <Part number="4" title="The Coniferous Climb" time="30–45 minutes">
      <Section type="Setup">
        <Block type="Secret Context" title="Setup">
          <Text>In this scene, the players will climb the rest of the way up the mountain through a steep forest with skyscraper-sized trees. They will stop to speak with an Elder Warden: a guardian of the forest who may assist them with the rest of the adventure and change the outcome of the game. At the top of the climb, they will face a mighty eagle who guards the entrance to The Trickster’s Tower.</Text>
        </Block>
      </Section>

      <Section type="Context">
        <Block type="Exposition" title="The Forest Climb">
          <Text>You see light in the distance of the tunnel and emerge at the base of a towering forest with trees so tall you can't see where they end. A lamplit wooden path spirals up into the forest, arching between the impressive tree trunks.</Text>
          <Text>You feel a sense of peace and wonder as you climb through the piney forest, much like how you felt when you first entered the Trickster's realm. Small forest critters dart between the trees, occasionally stopping to sniff the air in your direction.</Text>
          <Text>After an hour of climbing, you feel like you're only halfway done. As you stop to take a short break, you notice a small branch on a tree nearby with leafy green sprouts that don't match the rest of the forest's pine needles. Several glowing wisps are floating around the branch.</Text>
        </Block>
      
      </Section>

      <Section
        type="Character"
        name="Lia: Elder Warden"
        pronouns="Her/Hers"
        hp="20"
        atk="3"
        image={elderWardenImage}
        specialAbilities={["Shapeshifter", "Regeneration"]}
      />

      <Section type="Encounter">
        <Block type="Secret Context" title="Encounter: The Elder Warden">
          <Text>Elder Wardens are guardians of nature who can take the form of nearby flora. They are solitary creatures who often guard entire forests by themselves. They rarely make themselves seen, but are known to occasionally help lost travelers who have good intentions. The Warden here is named Lia (they/them).</Text>
          <Text>There is only one law in Warden society: no trees shall be harmed. The penalty for violating this law is severe. Lia has been furious at The Trickster ever since they cut down a tree to build Treasure Town, and has been waiting for adventurers to help drive the intruder away.</Text>
          <Text>Offer the players a conversation with Lia. If Lia detects that The Party has good intentions, they will offer to help them defeat The Trickster. But Lia requires a show of good faith, and will only accompany The Party if the adventurers give up all of the treasure they received from Fiona. If they do, Lia will extend their arms, absorbing the treasure into their form.</Text>
          <Text>If the players refuse to hand over their treasure, Lia will wish them luck, and disappear back into the forest.</Text>
          <Text>Then, the players will ascend through the rest of the forest until they emerge through the treetops and the path they are on extends to a cliff on the top of the mountain. You may use the optional Lichen encounter below before they reach the top, or skip ahead to the encounter with the Giant Eagle.</Text>
        </Block>
      
        <Block type="Exposition" title="Meeting Lia">
          <Text>As you look more closely at the mysterious branch, a figure emerges from tree trunk and walks toward you. They appear to be a person made of gnarled bark, wearing a slight smile and a tunic of moss.</Text>
          <Text>They greet you with a gentle yet powerful voice, asking where you come from and why you are here.</Text>
        </Block>
      </Section>
      
      <Section
        type="Character"
        name="Lichen"
        pronouns="He/His"
        hp="12"
        atk="2"
        specialAbilities={["Extra Attack"]}
        image={lichenImage} />
      
      <Section type="Encounter">
        <Block type="Secret Context" title="Optional Encounter: Lichen Attack">
          <Text>If you want to challenge your players with more combat, or if you feel the adventure is moving too quickly, you can ambush the players in this scene with a lichen attack. The lichens will not attack if Lia is accompanying the party.</Text>
          <Text>If the players have mostly chosen uncommon treasure, ambush them with a number of Lichen equal to the number of players in the party minus one. For a more difficult encounter, ambush the players with a number of Lichen equal to the number of players in the party.</Text>
          <Text>If the players are close to defeat or would die in this scene, you may choose to have the lichens steal one or more of their items and retreat back into the forest.</Text>
        </Block>
      
        <Block type="Exposition" title="Lichens Attack!">
          <Text>As the path through the trees narrows, you notice the trees in this area are covered in a strange green moss. There are swords and spears stuck into some of the tree trunks around you.</Text>
          <Text>You hear a creaking sound around you as mossy tentacles emerge from hollow tree branches, grabbing some nearby weapons. The tentacled logs lumber toward you, swinging sharp objects in the air!</Text>
        </Block>
      </Section>
    </Part>
  )
}
